import React, { Component } from 'react'
// get url, generate title

export default class NotFound extends Component {
  componentDidMount () {
    console.log(this.props.history)
  }

  render () {
    return (
      <div id="container">
      </div>
    )
  }
}
