export const symbols = [
  { name: 'Bitcoin', symbol: 'BTC', description: 'Bitcoin is the first successful in history and major cryptocurrency by market capitalization. It is a decentralized digital currency without a central bank or single administrator that can be sent from user to user on the peer-to-peer bitcoin network without the need for intermediaries.', video: 's4g1XFU8Gto' },
  { name: 'Ethereum', symbol: 'ETH', description: 'Ethereum is an open source, public, blockchain-based distributed computing platform and operating system featuring smart contract functionality. It supports a modified version of Nakamoto consensus via transaction-based state transitions.', video: 'TDGq4aeevgY' },
  { name: 'Lunyr', symbol: 'LUN', description: 'Lunyr is an Ethereum-based decentralized crowdsourced encyclopedia which rewards users with app tokens for peer-reviewing and contributing information. They aim to be the starting point of the internet for finding reliable, accurate information.', video: '_AkWSjSBIX4' },
  { name: 'Maid Safe Coin', symbol: 'MAID', description: 'MaidSafeCoin is a product of the MaidSafe company that aims to revolutionize the way people store their data. The company stores its data in a distributed manner across the blockchain, that utilises the extra storage space and computing time provided by its users.', video: 'Bx6BOvlUwPk' },
  { name: 'Monaco', symbol: 'MCO', description: 'Monaco is a payment and cryptocurrency platform that operates its own native currency called MCO token. It also offers Monaco Visa cards, and a mobile wallet app that allows users to easily buy, exchange, and spend fiat and cryptocurrencies, such as bitcoin and ethereum, at real-time interbank exchange rates.', video: 'cMFfWoIGS-Q' },
  { name: 'Mithril', symbol: 'MTH', description: 'Mithril (MITH) is a decentralized social media platform on the Ethereum Blockchain that rewards users for creating content.', video: 'eTGnuQc7Tc8' },
  { name: 'ABYSS', noIcon: true, symbol: 'ABYSS', description: 'Abyss Token serves as the native (platform-specific) digital currency used for shopping, in-game purchases, rewards and payouts on The Abyss platform. Abyss Tokens are issued on the Ethereum network and complies with the ERC20 standard.', video: 'SRHtI0MDkyA' },
  { name: 'XRP', symbol: 'XRP', description: 'Ripple is a real-time gross settlement system, currency exchange and remittance network created by Ripple Labs Inc., a US-based technology company. The ledger employs the decentralized native cryptocurrency known as XRP, which as of September 2018 was the second largest coin by market capitalization.', video: 'NU1r0uheIfM' },
  { name: 'Maker', symbol: 'MKR', description: 'Maker (MKR) is a cryptocurrency and a governance token. Cryptocurrency Maker (MKR) is a digital token created on the Ethereum platform of the project Maker, the main purpose of which is to create a line of decentralized digital assets that would be tied to the value of real instruments such as currency, gold, etc.', video: 'ndjI7ajRIV4' },
  { name: 'Cardano', symbol: 'ADA', description: 'Cardano is a decentralized, blockchain based system, powered by the ADA coin, the cryptocurrency which runs on the Cardano platform. Much like Ethereum, it is a smart contract-based system and can be used to run Decentralized Apps (called DApps).', video: 'Do8rHvr65ZA' },
  { name: 'Aion', symbol: 'AION', description: 'AION is the first 3rd generation blockchain network that allows different blockchain networks to communicate between themselves on a global scale.', video: 'o2zZq9GNB4Y' },
  { name: 'Neo', symbol: 'NEO', description: 'NEO is a blockchain platform and cryptocurrency, which is designed to digitize assets using smart contracts, aiming to bring blockchain to the masses. It supports common programming languages like Javascript and C++, with improvements to its technology, making it less vulnerable to hackers than Ethereum.', video: '4f0swLLNUC4' },
  { name: 'Synereo', symbol: 'AMP', description: 'Synereo is a decentralized social networking / content delivery platform that generates value for all participants. They have a fire-tested distributed tech stack as well as interface with the Blockchain.', video: 'ACLGk2Algug' },
  { name: 'Numeraire', noicon: true, symbol: 'NMR', description: 'The Numeraire cryptocurrency is the product from the hedge fund company Numerai which incentivizes scientists to predict financial models using encrypted data provided to them. NMR is also used by scientists, who are supposed to stake tokens on their models to prevent tampering.', video: 'HcO2D2K6luI' },
  { name: 'Nexo', symbol: 'NEXO', description: 'Nexo is a blockchain-based overdraft system that allows users to make instant crypto loans. At the same time, they can access the value of their crypto assets. Nexo was created by Credissimo, a leading FinTech group that has been serving millions of users from all over Europe for more than a decade.', video: 'hRY0vyDizdo' },
  { name: 'Aragon', symbol: 'ANT', description: 'Aragon is a decentralized app (dApp) on the Ethereum blockchain that allows anyone to create and manage a decentralized organization. The project is open source and led by the Aragon Foundation. It also includes a token, ANT, that grants voting rights to make decisions about the direction of future development.', video: 'AqjIWmiAidw' },
  { name: 'NGC', symbol: 'NGC', description: 'NGC is a decentralized cryptocurrency for trading and investing in financial markets, virtual goods and cryptocurrencies. NGC is the driving force of the whole NAGA Ecosystem — starting from being a primary form of all fees, to serving as the base currency for any transactions.', video: 'RL30V0Ne-5E' },
  { name: 'Ardor', symbol: 'ARDR', description: 'Ardor is a blockchain-as-a-service platform (BaaS) designed and created by a competitive team of technology enthusiasts at Jelurida, the parent company responsible for building Ardor and NXT blockchain platforms. Ardor provides businesses with a solution wherein they need no coding to set up a blockchain.', video: 'iPA7f1iJzf0' },
  { name: 'Nimiq', noicon: true, symbol: 'NIM', description: 'Nimiq is an open-source decentralized peer-to-peer payment protocol for the world wide web. By offering browser-based access, Nimiq aims to lower barriers to entry, and therefore increase blockchain adoption, for communities of new users who may be put off by the friction of using bitcoin and other cryptocurrencies.', video: 'hq4EQW908UE' },
  { name: 'ARK', symbol: 'ARK', description: 'The ARK coin is the cryptocurrency that fuels the platform. The ARK team wants to create a sandbox development kit that makes deploying your own blockchain easy and efficient, and they want their platform to be used as a bridge between various blockchains.', video: 'WUjDa7iZcAE' },
  { name: 'Aeron', symbol: 'ARN', description: 'Blockchain for Aviation Safety.', video: 'We9tH-WIdb0' },
  { name: 'Nxt', symbol: 'NXT', description: 'Nxt is an open source cryptocurrency and payment network launched in 2013 by anonymous software developer BCNext. It uses proof-of-stake to reach consensus for transactions—as such there is a static money supply and, unlike bitcoin, no mining.', video: 'g1j6wXRILOg' },
  { name: 'Cosmos', symbol: 'ATOM', description: 'Cosmos (ATOM) is a decentralized ecosystem of independent blockchains that its creator, All In Bits Inc (dba Tendermint Inc), hopes is the foundation for the next generation of internet technology.', video: 'b-ytzLX-2EE' },
  { name: 'OmiseGo', symbol: 'OMG', description: 'OMG coin is a white-label eWallet. It was built on the Ethereum blockchain by a Thai financial services company called Omise. Its full name is OmiseGo. ... OmiseGo will allow users to transfer coins from one blockchain to another without using a traditional exchange.', video: 'BQuhh5F88Ss' },
  { name: 'Ontology', symbol: 'ONT', description: 'Ontology is a cryptocurrency that describes itself as a “high-performance public multi-chain project and a distributed trust collaboration platform.” It\'s designed to help businesses use blockchain technology by offering tools such as identity verification and data exchange.', video: 'VAs122Dc9Wk' },
  { name: 'Particl', symbol: 'PART', description: 'Particl is an open-source and decentralized privacy platform built on the blockchain specifically designed to work with any cryptocurrency. It allows decentralized applications (Dapps) of all sorts to be built within a secure, highly-scalable environment and be integrated directly into Particl\'s official wallet.', video: 'PRxmGh24ILU' },
  { name: 'Bitcoin Diamond', symbol: 'BCD', description: 'Bitcoin Diamond (BCD) is a fork of Bitcoin with ne proof of work algorithm trying to improve speed of transactions.', video: '7I4N_VuXL5o' },
  { name: 'Basic Attention Token', symbol: 'BAT', description: 'The Basic Attention Token, or BAT, is a token for a blockchain-based ad platform. The project aims to improve online advertising by cutting out third party ad exchanges, protecting user privacy, reducing ad fraud, and sharing revenue with users to reward them for their attention.', video: '7p5cxCaUwm4' },
  { name: 'Bitcoin Cash', symbol: 'BCH', description: 'Bitcoin Cash is a cryptocurrency that is fork of Bitcoin with higher block weight trying to improve speed of transactions. Bitcoin Cash is a spin-off or altcoin that was created in 2017. In 2018 Bitcoin Cash subsequently split into two cryptocurrencies: Bitcoin Cash, and Bitcoin SV.', video: 'zIMdlUlqbS8' },
  { name: 'Paxos Standard Token', symbol: 'PAX', description: 'The Paxos Standard Token is a stablecoin running on Ethereum that attempts to maintain a value of US$1.00. The supply of PAX is collateralized by US dollars held in US banks.', video: '9Vv1uRktnR0' },
  { name: 'TenXPay', symbol: 'PAY', description: 'TenX is a payment platform that facilitates digital and physical modes of transaction for cryptocurrencies to any merchant, even if they don\'t accept cryptocurrencies.', video: 'f0kAqzaKS8M' },
  { name: 'BitDegree', noicon: true, symbol: 'BDG', description: 'BitDegree is a blockchain powered education platform that aims to revolutionize education and the blockchain technology. The platform offers students best online courses with a transparent a clear blockchain based achievement tracking and rewards system.', video: 'RuckLtuInNY' },
  { name: 'Better Betting', noicon: true, symbol: 'BETR', description: 'The smart escrow token', video: 'B28WOawYPqc' },
  { name: 'Red Pulse Phoenix Binance Coin', noicon: true, symbol: 'PHB', description: 'Red Pulse, a market intelligence platform focused on delivering information about China\'s business environment, has announced an integration with Binance\'s new blockchain, Binance Chain.', video: '1yWVM196kCY' },
  { name: 'BANKEX', symbol: 'BKX', noicon: true, description: 'Bankex is an organization working to revolutionize the capital markets with its Proof of Asset (PoA) protocol-run platform. Its main model is the BaaS - Bank as a Service - which enables the users to manage their assets online on a blockchain network.', video: 'hVEdzFHCVeM' },
  { name: 'Pillar', symbol: 'PLR', description: 'The Pillar Coin is a meta token. All necessary tokens can be used to pay for goods and services that users use. While the wallet and platform are open and free, users pay for the various PDL services with the Pillar Coin they use.', video: 'X-uEu2mZVCg' },
  { name: 'PumaPay', noicon: true, symbol: 'PMA', description: 'PumaPay’s disruptive payment solution combines the flexibility and ease-of-use of payment cards with the advantages of blockchain technology.', video: 'xSwdv1KHLj8' },
  { name: 'Binance token', symbol: 'BNB', description: 'Binance coin is a digital currency issued by the cryptocurrency exchange Binance. ... It is based on the Ethereum blockchain and similar to Ether, the BNB token also fuels all operations on Binance.com. Binance coin is used by users to pay the fees levied by Binance exchange for using the services.', video: 'zNVNno9LAjo' },
  { name: 'Polymath', symbol: 'POLY', description: 'Polymath is a security token platform that uses the ST-20 token standard to ensure compliance with government regulations when issuing digital securities. The difference between an ICO (initial coin offering) and an STO (securities token offering) is the resulting equity stake in the company.', video: 'cIt47H4NG0c' },
  { name: 'Bancor Network Token', symbol: 'BNT', description: 'The Bancor Network is a cross-chain cryptocurrency conversion platform that lets you convert between Ethereum and EOS tokens (others are in the works) without the need of a middleman or other third party. Bancor Network Token (BNT) is the intermediary token used by Bancor to initiate exchanges.', video: 'ENEiYn2UYL4' },
  { name: 'Power Ledger', symbol: 'POWR', description: 'Power Ledger (POWR) is an Australian blockchain-based cryptocurrency and energy trading platform that allows for decentralized selling and buying of renewable energy.', video: 'jMqMRs34_u8' },
  { name: 'Bread Token', symbol: 'BRD', description: 'The Bread Token (BRD) provides discounts and additional services when using the popular Bread wallet app. Bread is the first cryptocurrency wallet to provide a loyalty and customer rewards program.', video: 'iRpB6pE1WsQ' },
  { name: 'ProCurrency', noicon: true, symbol: 'PROC', description: 'ProCurrency is the commerce coin. It is a decentralized p2p Blockchain that focuses on solutions for consumers and businesses by rewarding them for utilizing cryptocurrency in their everyday life. The technology around PROC helps the masses experience cryptocurrency without it seeming overly complicated.', video: 'dF34ZwmYrw8' },
  { name: 'Patientory', noicon: true, symbol: 'PTOY', description: '', video: 'apcHSC1zXbk' },
  { name: 'Quantum', symbol: 'QTUM', description: 'Qtum is a cryptocurrency that combines ethereum\'s smart contract functionality with bitcoin\'s security to create a coin that is suitable for adoption by large organizations.', video: '8zJVOTyit9k' },
  { name: 'Bitcoin Gold', symbol: 'BTG', description: 'Bitcoin Gold is a cryptocurrency, a hard fork of Bitcoin, changing mining algorithm, adding ability to mine on GPUs.', video: '_sNyj_tlQSw' },
  { name: 'BitTorrent', symbol: 'BTT', description: 'BitTorrent Speed enhances the BitTorrent protocol for all users and all torrent clients. BitTorrent (BTT) tokens can be bid in exchange for faster downloads. Tokens will be stored in a built-in wallet and can be exchanged for TRON (TRX) tokens through a decentralized exchange.', video: 'ijwzE9YSpXs' },
  { name: 'Ripio Credit Network', symbol: 'RCN', description: 'Ripio Credit Network (RCN) is a global peer-to-peer credit network based on cosigned smart contracts that facilitates connections between lenders and borrowers across the world, on any currency.', video: 'EQbsUt89Gc4' },
  { name: 'Civic', symbol: 'CVC', description: 'Civic is an identity verification platform which is meant to make life easier, not only for the user, but also for the entity who wishes to verify the identity.', video: '2XDGX41nr1o' },
  { name: 'Dai', symbol: 'DAI', description: 'Dai is a decentralized cryptocurrency stabilized against the value of the US dollar. Created via the Makers (MKR) Dai Stablecoin System, it uses margin trading to respond to changing market conditions and preserve its value against the major world currencies.', video: 'YACEaUJy1fg' },
  { name: 'Augur', symbol: 'REP', description: 'Augur is decentralized oracle and prediction market protocol.', video: 'yegyih591Jo' },
  { name: 'Dash', symbol: 'DASH', description: 'Dash is an open source cryptocurrency and is a form of decentralized autonomous organization (DAO) run by a subset of users, called "masternodes". It is an altcoin that was forked from the Bitcoin protocol. The currency permits fast transactions that can be untraceable.', video: 'S0oNO3mbBE8' },
  { name: 'iEx.ec', symbol: 'RLC', description: 'iExec is an open-source, decentralized cloud computing platform, running on Ethereum blockchain. iExec allows decentralized applications (dApps) an on-demand access to computing resources and technologies on iExec cloud.', video: 'xfIThShzefQ' },
  { name: 'Salt', symbol: 'SALT', description: 'SALT lending is a platform that provides Blockchain-Backed Loans. SALT (Secured Automated Lending Platform) enables you to put up your crypto as collateral in exchange for a cash loan.', video: 'SvP7cJHIryw' },
  { name: 'Decred', symbol: 'DCR', description: 'The Decred coin is a digital currency which offers complete autonomy to its stakeholders for decentralized decision making and nifty transition between the different set of rules. Its cryptocurrency is denoted by the symbol DCR, with a total supply of 21 million coins.', video: 'GAMp5V_gG1E' },
  { name: 'DENT', symbol: 'DENT', description: 'DENT Coin is a new cryptocurrency that promises to disrupt the mobile data industry in the world. The company Dentcoin is called DENT Wireless Limited.', video: 'oyUEITqOVCM' },
  { name: 'SmartCash', symbol: 'SMART', description: 'SmartCash is a community-driven cryptocurrency built on the zerocash protocol. It prioritizes privacy and fast transaction times, with the goal of achieving merchant adoption.', video: 'HF49aSchA9k' },
  { name: 'Enjin Coin', symbol: 'ENJ', description: 'Enjin Coin is a cryptocurrency for virtual goods created by Enjin. Enjin is the “largest gaming community platform online” with over 250,000 gaming communities and 18.7 million registered gamers.', video: 'OCeg_i782f0' },
  { name: 'DigiByte', symbol: 'DGB', description: 'DigiByte uses a multi-algorithm approach that creates different proof of work (PoW) mining methods & increases mining capacity & hence, the accessibility to DigiByte mining pools. This prevents centralization and secures the network against a 51% attack.', video: 'UfRY2EhjLU' },
  { name: 'Storj', symbol: 'STORJ', description: 'Storj is an open source, decentralized file storage solution. It uses encryption, file sharding, and a blockchain-based hash table to store files on a peer-to-peer network. The goal is to make cloud file storage faster, cheaper, and private.', video: 'vl3bUzfn2lg' },
  { name: 'DigixDAO', symbol: 'DGD', description: 'DigixDAO is a Decentralized Autonomous Organization creating a cryptocurrency backed by bars of gold. DGD is the native Ethereum-based ERC-20 cryptocurrency token governing the Digix network. ... More DG_ tokens representing silver, platinum, and other precious metals are in the works.', video: 'RE9G_H0nV7o' },
  { name: 'Stratis', symbol: 'STRAT', description: 'Stratis is a Proof-of-Stake (PoS) blockchain, which limits miners to a proportion of their own coin supply.', video: 'eug20T9O6Y0' },
  { name: 'Stox', nocion: true, symbol: 'STX', description: 'Stox is a cryptocurrency based on the Ethereum blockchain, while it makes use of the Bancor protocol. It is intended to be used for forecasting the financial markets.', video: 'SvQw5HzNBDA' },
  { name: 'Digitex Future', noicon: true, symbol: 'DGTX', description: 'The Digitex Futures Exchange is a decentralized platform for trading futures contracts (also known as derivatives or options) on cryptocurrencies, commodities, and other assets. Digitex offers zero-fee transactions through it native DGTX token, which is used to bet against the future price of BTC.', video: 'Bbz_uqstEgY' },
  { name: 'Dogecoin', symbol: 'DOGE', description: 'Dogecoin is a cryptocurrency featuring a likeness of the Shiba Inu dog from the "Doge" Internet meme as its logo. Introduced as a "joke currency" on 6 December 2013.', video: '000al7ru3ms' },
  { name: 'Swarm City', nocion: true, symbol: 'SWT', description: 'Swarm City is a decentralized, peer-to-peer commerce platform which gives users access to a decentralized global community of service providers and consumers. It is the built on the Ethereum Blockchain and will be available as web-based app, Android and iOS platforms.', video: '4eMaCvyOO8M' },
  { name: 'Telcoin', symbol: 'TEL', description: 'lcoin is a ERC20 token based on Ethereum blockchain, that aims at partnering with telecommunication giants like Verizon and AT&T to integrate the mobile industry with the cryptocurrencies.', video: 'xlPJWpN-RQk' },
  { name: 'Edgeless', symbol: 'EDG', description: 'The Edgeless coin is an utility token used for playing the Edgeless casino games. It is an Ethereum based token and follows the ERC20 protocol.', video: 'bbwjQkwL6nM' },
  { name: 'Trustcoin', noicon: true, symbol: 'TRST', description: 'The WeTrust platform aims to provide decentralized products and services such as their Trusted Lending Services in order to make stable banking or credit solutions globally accessible. TrustCoins are ERC20 currency tokens on the Ethereum blockchain fueling the WeTrust platform.', video: '3BpFxS-4g_8' },
  { name: 'EOS', symbol: 'EOS', description: 'EOS.IO is a blockchain protocol powered by the native cryptocurrency EOS. The protocol emulates most of the attributes of a real computer including hardware with the computing resources distributed equally among EOS cryptocurrency holders.', video: '9C3qFqJQ4Pw' },
  { name: 'Tron', symbol: 'TRX', description: 'Tron is a blockchain-based decentralized platform that aims to build a free, global digital content entertainment system with distributed storage technology, and allows easy and cost-effective sharing of digital content.', video: 'gU6Jfz2jOHA' },
  { name: 'TrueUSD', symbol: 'TUSD', description: 'TrueUSD is a USD-backed ERC20 stablecoin that is fully collateralized, legally protected, and transparently verified by third-party attestations. TrueUSD uses multiple escrow accounts to reduce counterparty risk and to provide token-holders with legal protections against misappropriation.', video: 'zFlVDDeMh9Y' },
  { name: 'Ethereum Classic', symbol: 'ETC', description: 'Ethereum Classic is an open-source, public, blockchain-based distributed computing platform featuring smart contract (scripting) functionality.', video: 'DrrQmzdx5m8' },
  { name: 'USDCoin', symbol: 'USDC', description: 'A stable cryptocurrency.', video: 'uuxi1Ig4uHk' },
  { name: 'Tether USD', symbol: 'USDT', description: 'Tether is a controversial cryptocurrency with tokens issued by Tether Limited. It formerly claimed that each token was backed by one United States dollar, but on March 14, 2019 changed the backing to include loans to affiliate companies.', video: 'zFlVDDeMh9Y' },
  { name: 'Ethos', symbol: 'ETHOS', description: 'ETHOS is the platform\'s access token that lets users securely manage cryptocurrency wallets, coins, and keys. It has ERC-20 audited contracts on the Ethereum blockchain and delivers lower-cost transactions that put you in control of your funds. Initially, ETHOS will be for accessing various features and paying fees.', video: 'U_0GjcF-VXE' },
  { name: 'Tether ERC20', noicon: true, symbol: 'YSDT20', description: 'Tether is a controversial cryptocurrency with tokens issued by Tether Limited. It formerly claimed that each token was backed by one United States dollar.', video: 'zFlVDDeMh9Y' },
  { name: 'VeChain', symbol: 'VET', description: 'VeChain is a cryptocurrency and smart contracts platform focused on supply chain management. It allows manufacturers to add sensors, such as RFID tags, to their products that can then record data onto the VeChain blockchain. Official website.', video: 'RiTYD-G5cW8' },
  { name: 'FunFair', symbol: 'FUN', description: 'FunFair is a decentralized, cryptocurrency-based casino gaming platform built on the Ethereum smart-contract blockchain. It uses offchain processing through Fate Channels to speed up network transactions and lower fee costs. The FUN token is the proprietary ERC-20 cryptocurrency token for the FunFair platform.', video: 'UyW3E94Ho5I' },
  { name: 'Viberate', symbol: 'VIB', description: 'Viberate crypto is an Ethereum-based decentralized talent ecosystem for live music. It connects artists, fans, talent management agencies and event organizers all together on the same platform.', video: 'MG1MvZr3XqA' },
  { name: 'Golem', symbol: 'GNT', description: 'Golem is a blockchain platform that uses the distributed cloud to make massive computing power available to the masses. The Golem Network aims to become the first \'world supercomputer\'. It allows GNT token holders to \'rent out\' their spare computing power.', video: 'FZb_w5JXvJ8' },
  { name: 'Gas', symbol: 'GAS', description: 'A cryptocurrency for NEO platform, GAS is the token used to pay the cost of execution on the NEO blockchain. Neo, formerly called as AntShares, is China\'s first open source blockchain platform. It was founded by Da Hongfei and Erik Zhang in 2014 with the aim of revolutionizing trade.', video: 'OmpRwGbhYe4' },
  { name: 'Waves', symbol: 'WAVES', description: 'Waves is a blockchain platform you can use to create your own custom token. It also includes a decentralized exchange, DEX, in which you can trade your newly created coin in a trading pair with any other Waves token.', video: '0Np-58p3OZQ' },
  { name: 'Gnosis', symbol: 'GNO', description: 'Gnosis Price Analysis: Bearish for now with long term potential. ... Gnosis (GNO) is a decentralized, open source prediction market protocol built on the Ethereum blockchain. Along with Augur, another early mover in the decentralized prediction market, Gnosis is one of the most high profile projects building on Ethereum.', video: 'yLHBRp28Vic' },
  { name: 'Wings DAO', symbol: 'WINGS', description: 'Wings is a platform which allows its users to create and manage decentralized autonomous organizations (DAOs), using a mixture of standard DAO governance, prediction marketing and crowdfunding to realize its goal.', video: 'HV2KWhXhGD8' },
  { name: 'Groestlcoin', symbol: 'GRS', description: 'Groestlcoin (GRS) is SEGWIT and Lightning Network ready! Groestlcoin is a fast and secure coin with almost ZERO fees, that is privacy oriented.', video: 'k7PxHCHcCWw' },
  { name: 'Waltonchain', noicon: true, symbol: 'WTS', description: 'Waltonchain is a blockchain that tracks radio-frequency identification (RFID) data throughout the supply chain. Waltoncoin (WTC) is the native cryptocurrency token of the mainchain, and users can create their own tokens on subchains.', video: 'mcxSe56jXqY' },
  { name: 'XEM', symbol: 'XEM', description: 'NEM is a dual-layer blockchain similar to Ethereum but written in Java, a popular computer programming language. NEM\'s proprietary crypto coin is XEM, which is harvested (mined) using a Proof-of-Importance (PoI) algorithm. (NEM, by the way, stands for New Economy Movement.)', video: '_Hct_rKHnts' },
  { name: 'Stellar', symbol: 'XLM', description: 'The Stellar network is a distributed blockchain based ledger and database that facilitates cross-asset transfers of value, including payments. ... In other words, Stellar is the payment network (Horizon API and Stellar Core) and Lumens (XLM) is the cryptocurrency.', video: '20gwXDQi55w' },
  { name: 'Gemini Dollar', symbol: 'GUSD', description: 'Gemini Trust Company, LLC (Gemini) is a digital currency exchange and custodian that allows customers to buy, sell, and store digital assets. ... All users are able to trade cryptocurrency and fiat currency on an open market', video: 'mmoOAoMwGNE' },
  { name: 'Humaniq', noicon: true, symbol: 'HMQ', description: 'Humaniq is a decentralized, Ethereum blockchain-based platform that provides access to banking tools for those who don\'t have any possibility to use traditional financial institutions.', video: 'prEPErb82Mg' },
  { name: 'Monero', symbol: 'XMR', description: 'Monero (XMR) is a cryptocurrency which focuses on being untraceable and private. Its design differs from Bitcoin\'s in a few key ways, but it should be understood as a cryptocurrency similar to Bitcoin – it can be used to buy and sell things, and can be exchanged for other coins or tokens.', video: 'TZi9xx6aiuY' },
  { name: 'Bitcoin Rhodium', noicon: true, symbol: 'XRC', description: 'Bitcoin Rhodium is a new crypto commodity, rare, limited and resistant cryptocurrency to store value for the future. It aims to provide not just a payment method, but a real, valuable commodity, which will become a prosperous investment element along with BTC.', video: '6BygsTEmGWM' },
  { name: 'Huobi Token', symbol: 'HT', description: 'HT is a decentralized digital asset based on Ethereum, with a limited total supply of 500 million, issued by Huobi Group. Huobi Group developed HT based on the token system issued and managed through blockchain and it can be used inside Huobi as well as the multiple scenarios of Huobi ecosystem.', video: '01fZLH5EiQs' },
  { name: 'Tezos', symbol: 'XTZ', description: 'Like bitcoin and ethereum, Tezos is a decentralized ledger which makes use of blockchain technology. Like ethereum, Tezos is designed to make use of smart contracts (“Tezos” is ancient Greek for “smart-contract,” according to the developers). Tezos is intended to be an evolving network.', video: 'PpEVnGU0oE0' },
  { name: 'Verge', symbol: 'XVG', description: 'Verge coin is a cryptocurrency that strives to make blockchain transactions anonymous and untraceable. Its native token is known as XVG. Verge\'s core team have signed its black paper with their usernames only. Verge launched in 2014.', video: 'Ul-MyoVtV54' },
  { name: 'Komodo', symbol: 'KMD', description: 'Komodo Platform (KMD) is a privacy-centric coin that promises to be a faster, safer, and ultimately decentralized cryptocurrency with its own established ecosystem. Komodo and other democratized currencies seek true decentralization of the blockchain itself and its production.', video: 'eBTMjFotWS8' },
  { name: 'Kyber', symbol: 'KNC', description: 'Kyber Network is an Ethereum-based protocol that allows the “instant exchange and conversion of digital assets (e.g. crypto tokens) and cryptocurrencies (e.g. Ether, Bitcoin, ZCash) with high liquidity.” It\'s similar to the 0x project but instead performs all its actions on the blockchain.', video: 'rs_zu2kHD-M' },
  { name: 'ZCoin', symbol: 'XZC', description: 'Zcoin is a decentralized, digital currency that primarily focuses on the “privacy” and “anonymity” aspects of cryptocurrency transactions. It is the first cryptocurrency that fully implemented Zerocoin protocol.', video: 'aEbawhzhLqM' },
  { name: 'Zap', noicon: true, symbol: 'ZAP', description: 'Zap Store is an Ethereum-based marketplace to find, list, and purchase oracles. ZAP is an ERC20 token that serves as currency on Zap\'s ecosystem. In order to create an oracle or make queries for data, both providers and subscribers must bond their ZAP, locking it up in an individual oracle.', video: 'kc4s7IcVU0E' },
  { name: 'ChainLink Token', symbol: 'LINK', description: 'LINK is an ethereum-based token used to power Chainlink\'s decentralized network. An API bridging service, Chainlink connects smart contract platforms – like decentralized finance products – to oracles containing real market data and event information.', video: 'ZB5glFcT88s' },
  { name: 'Zcash', symbol: 'ZEC', description: 'The Zcash is a decentralized and an open source cryptocurrency, which provides and offers users with the selective transparency of their transactions. The peer to peer cryptographic research on which the Zcash technology is based is developed from the battle-tested Bitcoin Core\'s codebase.', video: 'Qs4ZqVYY-DA' },
  { name: 'Loopring', symbol: 'LRC', description: 'Loopring is a decentralized exchange protocol and an “automated execution system” built on Ethereum that will allow its users to trade assets across exchanges. Loopring is blockchain agnostic, meaning that any platform that uses smart contracts (e.g., NEO, Ethereum, Qtum) can integrate with Loopring.', video: 'NFaheriE19w' },
  { name: 'Lisk', symbol: 'LSK', description: 'LSK, or Lisk coin, is the currency associated with Lisk, used primarily in the DApps on the Lisk main chain itself. Unlike Bitcoin and others, it is not a digital currency that merchants will accept as payment, but rather a currency for developers using or wishing to use the platform.', video: 'rTgAC-XTInI' },
  { name: 'Horizen (ZenCash)', symbol: 'ZEN', description: 'Horizen (formerly called ZenCash) is a privacy-focused, zk-SNARKs based blockchain platform that forked from Zclassic, itself a Zcash fork. ZEN is the native cryptocurrency coin used by the Horizen network.', video: '4mDG4W7uem0' },
  { name: 'Litecoin', symbol: 'LTC', description: 'Litecoin is a peer-to-peer cryptocurrency and open-source software project. Creation and transfer of coins is based on an open source cryptographic protocol and is not managed by any central authority. Litecoin was an early bitcoin spinoff or altcoin, starting in October 2011.', video: 'Xn7RUr3EW2E' },
  { name: '0x token', symbol: 'ZRX', description: 'Powers decentralized peer-to-peer exchange of assets on the Ethereum blockchain.', video: 'c04eIt3FQ5I' }
]
